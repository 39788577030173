<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm10 md6>
        <v-row fill-height>
          <v-col cols="12" md="12" justify="center" align-self="center">
            <v-card height="100%" flat color="transparent" class="text-center">
              <v-img
                contain
                height="20vh"
                :src="this.$store.getters.company.logo"
              ></v-img>

              <v-card-text class="text-center">
                <v-row>
                  <v-col v-for="(child, index) in items" :key="index" cols="6">
                    <v-card
                      elevation="10"
                      class="pa-3"
                      :to="{ name: child.to, params: child.params }"
                    >
                      <v-avatar rounded>
                        <v-icon :color="child.color">
                          {{ child.icon }}
                        </v-icon>
                      </v-avatar>
                      <v-card-text class="pa-0" v-text="child.name" />
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card elevation="10" class="pa-3" @click="gotoCart()">
                      <v-avatar rounded>
                        <v-icon color="teal darken-2"> mdi-cart-outline </v-icon>
                      </v-avatar>
                      <v-card-text class="pa-0" v-text="cart" />
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="6">
                    <v-card elevation="10" class="pa-3" to="Android">
                      <v-avatar rounded>
                        <v-icon color="teal darken-2"> mdi-bluetooth </v-icon>
                      </v-avatar>
                      <v-card-text class="pa-0">Settiing</v-card-text>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <!-- <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      absolute
      bottom
      dark
      :color="snackToast.color"
      elevation="24"
    >
      {{ this.snackToast.text }}
    </v-snackbar> -->

    <v-bottom-sheet v-model="snackToast.dialog" :timeout="snackToast.timeout_error">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="green" dark v-bind="attrs" v-on="on"> Open Persistent </v-btn>
      </template> -->
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="snackToast.dialog = !snackToast.dialog"
        >
          close
        </v-btn>
        <div class="py-3">This is a bottom sheet using the persistent prop</div>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-navigation
      app
      grow
      background-color="#064c72"
      dark
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-btn
        v-if="this.company.mobile"
        target="_blank"
        :href="open_whatsapp(company.mobile)"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn v-if="this.company.mobile" :href="'tel:' + company.mobile">
        <v-icon>mdi-phone-outline</v-icon>
      </v-btn>
      <v-btn v-if="this.company.facebook">
        <!-- <span>Facebook</span> -->
        <v-icon>mdi-facebook</v-icon>
      </v-btn>

      <v-btn v-if="this.company.instagram" @click="open_instagram(company.instagram)">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
// import { webserver } from "../../services/webserver.js";
//import { Console } from "console";
//import createParty from "../../utils/createParty.js";
// import VueQr from "vue-qr";
export default {
  name: "Welcome",
  components: {},
  data() {
    return {
      logo: "",
      logo_lg: "",
      color: "teal darken-2",
      flat: null,
      isXs: false,
      open: [],
      mobile: null,
      f_required: [(v) => !!v || "Requerido"],
      act: true,
      act_code: "",
      valid: false,
      loading_status: false,
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 6 || "EL código debe tener 6 números].",
      ],
      countDown: 60,
      token: "",
      snackToast: {
        timeout_error: -1,
        dialog: false,
        text: "",
        color: "success",
      },
      profile_dialog: false,
      countries: [],
      state: [],
      city: [],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      token_dialog: false,
      company: this.$store.getters.company,
      profile: this.$store.getters.profile,
      items: [
        {
          id: 0,
          name: "Catálogo",
          icon: "mdi-storefront-outline",
          to: "Store",
          color: "#064c72",
        },
        // {
        //   id: 2,
        //   name: "Mis Ordenes",
        //   icon: "mdi-file-document-multiple-outline",
        //   to: "MyOrders",
        //   color: "#064c72",
        // },
        // {
        //   id: 3,
        //   name: "Mi Perfil",
        //   icon: "mdi-account-outline",
        //   to: "Profile",
        //   color: "#064c72",
        // },
      ],
      cart: 0,
    };
  },

  beforeMount() {
    this.company = this.$store.getters.company;
    this.profile = this.$store.getters.profile;
  },
  mounted() {
    this.$store.dispatch("setNavStatus", true);

    this.cartqty();
    //this.snackToast.dialog = true;
  },
  watch: {
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        //this.menu = this.menu[newVal.user_access];
        this.client = this.$store.getters.profile;
      }
    },
  },
  computed: {},
  methods: {
    cartqty() {
      //console.log("cartqty", localStorage.cart ? localStorage.cart : "no cart");
      if (localStorage.cart) {
        var itms = JSON.parse(Buffer.from(localStorage.cart, "base64"));
        itms.forEach((itm) => {
          this.cart += parseFloat(itm.qty);
        });
      }
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    open_instagram(data) {
      window.open("https://www.instagram.com/" + data, "_blank");
    },
    open_whatsapp(data) {
      data = data.replace("-", "");
      data = data.replace("(", "");
      data = data.replace(")", "");
      data = data.replace(" ", "");
      var URL =
        "https://api.whatsapp.com/send?phone=+57" +
        data +
        "&text=" +
        encodeURI("Deseo más información ...");
      return URL;
    },

    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    gotoCart() {
      this.$router.push({ name: "Store", params: { Cart: true } });
    },
  },
};
</script>

<style></style>
