<template>
  <v-app id="inspire">
    <v-app-bar :value="$store.getters.getNavStatus" flat app>
      <v-app-bar-nav-icon @click="setdrawer" to="/">
        <v-img contain :src="company.logo" height="40"></v-img>
      </v-app-bar-nav-icon>
      <v-toolbar-title> {{ this.company.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu transition="slide-x-transition" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <img
              :src="$store.getters.profile.Picture"
              v-if="$store.getters.profile.Picture"
            />
            <v-icon v-else>mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <template>
          <v-list v-if="this.$store.state.profile.code">
            <v-list-item to="Profile">
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ this.$store.state.profile.name }}
                </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="MyOrders">
              <v-list-item-avatar>
                <v-icon>mdi-file-document-multiple-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Mis Ordenes </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-avatar>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Salir </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item to="Registration">
              <v-list-item-avatar>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Registrarse </v-list-item-title>
                <small>{{ this.$store.state.profile.email }}</small>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
    </v-app-bar>

    <v-main class="bg-pos">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-scale-transition>
      <v-btn
        fab
        dark
        v-show="fab"
        v-scroll="onScroll"
        fixed
        bottom
        right
        color="#7494a4"
        @click="toTop"
        class="fabbtn"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Una actualización disponible.
      <v-btn text @click="refreshApp"> ACTUALIZAR </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="snackToast.dialog"
      :timeout="snackToast.timeout_error"
      absolute
      centered
      vertical
      outlined
      :color="snackToast.color"
      elevation="24"
    >
      {{ this.snackToast.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackToast.dialog = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog :value="loadingstatus" hide-overlay persistent max-width="480">
      <v-card color="primary" dark>
        <v-card-text>
          Trabajando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import update from "./mixins/update";
import { webserver } from "./services/webserver.js";
import createItem from "./utils/createUser.js";
import createParty from "./utils/createParty.js";

// const TIME_IN_BOUNDARY = 8;
// const TIME_OUT_BOUNDARY = 24;
// const INTERVAL = 900 * 1000;

export default {
  beforeMount() {
    this.$store.dispatch("setHost", window.location.host);
    this.$store.dispatch("setHost", "qunno.sw67.ca");
    //this.$store.dispatch("setHost", "liquorstore.sw67.ca");
    //this.$store.dispatch("setHost", "marquesa.sw67.ca");
    // this.$store.dispatch("setHost", "sw67.ca");

    // var company = window.localStorage.getItem("company");
    // if (company) {
    //   var store = JSON.parse(
    //     this.CryptoJS.AES.decrypt(company, process.env.VUE_APP_QR4).toString(
    //       this.CryptoJS.enc.Utf8
    //     )
    //   );
    //   store.logo =
    //     "https://" +
    //     this.$store.getters.getHost +
    //     "/logos/" +
    //     store.account +
    //     "/" +
    //     store.code +
    //     "/logo.webp";
    //   this.$store.dispatch("setCompany", store);

    //   if (this.$store.state.profile.account !== this.$store.state.company.account) {
    //     this.logout();
    //   }
    // } else {
    //   this.get_company();
    // }
    this.get_company();

    var profile = window.localStorage.getItem("profile");
    if (profile) {
      var client = JSON.parse(
        this.CryptoJS.AES.decrypt(profile, process.env.VUE_APP_QR4).toString(
          this.CryptoJS.enc.Utf8
        )
      );
      this.$store.dispatch("setProfile", client);
    }
    // console.log(client);
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$vuetify.goTo(0);
    this.onResize();
    this.menu = this.$store.getters.getMenuItems;
  },
  data: () => ({
    updateExists: false,
    loadingstatus: false,
    company: createParty(),
    dialog: true,
    profile: { name: null },
    drawer: true,
    isXs: false,
    item: createItem(),
    client: createParty(),
    navStatus: true,
    open: [],

    menu: [],
    tracker: null,
    fab: null,
    mini: true,
    valid: false,
    reg_dialog: false,
    f_email_val: [
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
    ],
    f_required: [(v) => !!v || "Requerido"],
    snackToast: {
      timeout_error: 2000,
      dialog: false,
      text: "",
      color: "success",
    },
    logo: "",
  }),
  mixins: [update],
  components: {},
  computed: {
    showDrawer() {
      return this.$store.getters.getMenuStatus && this.drawer;
    },
    showLoading() {
      return this.$store.getters.getLoading;
    },
  },
  watch: {
    "$store.getters.getLoading"(newVal) {
      if (newVal) {
        this.loadingstatus = this.$store.getters.getLoading;
      }
    },
    "$store.getters.getMenuStatus"(newVal) {
      if (newVal) {
        this.drawer = true;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        //this.menu = this.menu[newVal.user_access];
        this.client = this.$store.getters.profile;
      }
    },
    "$store.getters.getMenuItems"(newVal) {
      if (newVal) {
        this.menu = newVal;
        //this.track();
      }
    },
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
    fab(value) {
      if (value) {
        this.color = "teal darken-2";
        this.flat = false;
      } else {
        this.color = "teal darken-2";
        this.flat = true;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.tracker);
  },
  methods: {
    setdrawer() {
      this.$store.dispatch("set_menu", !this.$store.getters.getMenuStatus);
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },

    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },

    logout() {
      localStorage.removeItem("profile");
      localStorage.removeItem("company");
      this.$store.dispatch("setProfile", createParty());
      this.$store.dispatch("setCompany", createParty());
      //this.$router.push("/");
    },
    async get_company() {
      var qry = {
        table: "stores",
        filters: [{ field: "url", operator: "=", value: this.$store.getters.getHost }],
      };
      this.$store.dispatch("setloading", true);
      console.log(qry);

      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_table",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      let result = await promise;

      if (result.length > 0) {
        result[0].logo =
          "https://" +
          this.$store.getters.getHost +
          "/logos/" +
          result[0].account +
          "/" +
          result[0].code +
          "/logo.webp";
        this.$store.dispatch("setCompany", result[0]);
        const encryptedText = this.CryptoJS.AES.encrypt(
          JSON.stringify(result[0]),
          process.env.VUE_APP_QR4
        ).toString();
        window.localStorage.setItem("company", encryptedText);
      }

      this.$store.dispatch("setloading", false);

      //console.log(result);
    },
  },
};
</script>

<style lang="css">
/*
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

.v-main {
  font-family: "Roboto Condensed", sans-serif !important;
}*/

.fabbtn {
  bottom: 80px !important;
}
/* .navigation-drawer {
  font-size: 0.6rem !important;
  font-family: "Roboto Condensed", sans-serif !important;
  max-width: 600px;
}
*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
/*
.fade-enter,
.fade-leave-to
 .fade-leave-active below version 2.1.8  {
  opacity: 0;
}

.bg-pos {
  background: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}*/
</style>
